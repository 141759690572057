// Palette
$p-color: #222222;
$s-color: #24292d;
$t-color: #777777;
$f-color: #707070;

$m-color: #dddddd;
$k-color: #000000;
$b-color: #ffffff;

$danger-color: #d9002a;
$success-color: #00d66b;
$warning-color: #ffa500;
$info-color: #ffce00;
$link-color: #a7a7a7;

$grey-a: #e4e4e4;
$grey-b: #cccccc;
$grey-c: #f8f8f8;
$grey-d: #888888;

$invalid-color: #dc3545;
$invalid-color-inverse: #ffc107;

$contact-color: #009dff;
$lead-color: #ffb100;
$account-color: #ff00b1;
// Font family
$p-font: 'Inter', sans-serif;

// Font size
$base-font-size: 16;

// Font weights
$fw-thin: 100; //
$fw-x-light: 200; //
$fw-light: 300; //
$fw-regular: 400; //
$fw-medium: 500; //
$fw-s-bold: 600; //
$fw-bold: 700; //
$fw-x-bold: 800; //
$fw-black: 900; //

// Img Path
$image-path: '../content/img/' !default;

// Font Path
$font-path: '../content/fonts' !default;

// Header height
$header-height: 7rem; //80px

// Sidebar Spacer
$sidebar: 14.375rem; // 230px
$sidebar-login: 29.5625rem; // 473px
