@use "sass:meta";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$md-fimmprimary: (
  50 : #e5e5e6,
  100 : #bdbfc0,
  200 : #929496,
  300 : #66696c,
  400 : #45494d,
  500 : #24292d,
  600 : #202428,
  700 : #1b1f22,
  800 : #16191c,
  900 : #0d0f11,
  A100 : #56acff,
  A200 : #2393ff,
  A400 : #0078f0,
  A700 : #006bd6,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$md-fimmaccent: (
  50 : #d19d23,
    100 : #f1e2bd,
    200 : #e8ce91,
    300 : #dfba65,
    400 : #d8ac44,
    500 : #d19d23,
    600 : #cc951f,
    700 : #c68b1a,
    800 : #c08115,
    900 : #b56f0c,
    A100 : #fff2e1,
    A200 : #ffdbae,
    A400 : #ffc47b,
    A700 : #ffb862,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.m2-define-palette($md-fimmprimary);


$app-accent: mat.m2-define-palette($md-fimmaccent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$app-typography: mat.m2-define-typography-config(
  $font-family: Inter
);

$fimmapp-theme: mat.m2-define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  ),
  typography: $app-typography,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fimmapp-theme);


@import './styles/variables.scss';
@import 'styles/scss/abstracts/variables.scss';
@import 'styles/scss/style.scss';


@layer tw-base,primeng, tw-components, tw-utilities ;


@layer tw-base {
  @tailwind base;
}

@layer primeng {
  @include meta.load-css("./styles/theme.css");
  @include meta.load-css("primeng/resources/primeng.css");
}


@layer tw-components {
  @tailwind components;
}
@layer tw-utilities {
  @tailwind utilities;
}

:root {
  $primary: #010703;
}

* { // override bootstrap colors
  $primary: #d19d23;
  $theme-colors: (
    "primary": $primary
  );
}

:root{
  --primary-500:red;
}



html, body { height: 100%; }
body,
body .p-component { margin: 0; font-family: Inter, "Helvetica Neue", sans-serif; }

.p-datatable .p-datatable-thead > tr > th {
  font-weight: 500 !important;
  border: none;
}
button.p-highlight {
  background-color: #d19d23aa;
  color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F0F0F0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d19d2389;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d19d23;
}
